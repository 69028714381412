import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Contenedor1 from './../../../img/contenedor_b.jpg';
import './home.css';
import mainImage from './../../../img/tomate.jpeg';
import cosecha from './../../../img/granjapng.png';
import image3 from  './../../../img/tomatepasta.jpg';
import cosechadora from './../../../img/tractor_new.jpg';
import largeTomatoe from './../../../img/large-tomate.jpeg';
import HALAL from './../../../img/HALAL_certificado.png';
import KOSHER from './../../../img/Kosher_Certificado.png';
import SQF from './../../../img/SQF_certificado.png';
import logo from './../../../../src/bs_foods_logo.png';
import barco from './../../../img/barco.jpg';

function Home(){
    return<Container className="home-container">
        <br/>
        <section id='firstElement'>
            <Row className="firstElement">
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <br/>
                    <h1 className='titles'>BS Foods</h1>
                    <p className='texts'>
                    Welcome to BS Foods, thank you for visiting our website and for letting us assist you. Don’t forget to take a look on our ‘contact’ section where you can let us know any feedback, questions and more. THANK YOU FOR YOU PREFERENCE! 
                    </p>
                    <br/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={4} xl={4} >
                    <img className='logoImage' src={logo}/>
                </Col>
                
            </Row>  
        </section>
        <section id='secondElement'>
            <Row>
                <Col xs={12} sm={12} md={12} lg={4} xl={4} className='justify-content-center' style={{paddingBottom:"10px !important"}}>
                    <br/>
                    <div className='imageContainer'>
                        <img className='image' src={cosecha}/>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={4} xl={4} className='justify-content-center' style={{paddingBottom:"10px !important"}}>
                    <br/>
                    <div  className='imageContainer' >
                        <img className='image' src={cosechadora} />
                    </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={4} xl={4} className='justify-content-center' style={{paddingBottom:"10px !important"}}>
                    <br/>
                    <div className='imageContainer'>
                        <img className='image' src={image3} />
                    </div>
                </Col>
            </Row>
            <br/>
            <Row>
            <Col xs={0} sm={0} md={0} lg={1} xl={1} className='justify-content-center' style={{paddingBottom:"10px !important"}} />
                <Col xs={12} sm={12} md={12} lg={5} xl={5} className='justify-content-center' style={{paddingBottom:"10px !important"}}>
                    <br/>
                    <div className='imageContainer'>
                        <img className='image' src={barco}/>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={5} xl={5} className='justify-content-center' style={{paddingBottom:"10px !important"}}>
                    <br/>
                    <div  className='imageContainer' >
                        <img className='image' src={Contenedor1} />
                    </div>
                </Col>
                <Col xs={0} sm={0} md={0} lg={1} xl={1} className='justify-content-center' style={{paddingBottom:"10px !important"}} />
            </Row>
            <br/>
        </section>
        <br/>
        <br/>
        <section id='thirdElement'>
            <Row className="thirdElement">
                <Col xs={12} sm={12} md={12} lg={6} xl={6} >
                    <h1 className='titles'>What is BS Foods?</h1>
                    <p className='texts'>
                    BS Foods emerges after 20 years of experience in Mexico, first in production and now distribution of tomato paste and fruits and veggies, representing a constant and trustworthy option that excels a higher level of quality, service and price.
                    </p> 
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className='imageContainer'>
                        <img className='image' src={mainImage}/>
                    </div>
                </Col>
            </Row>
        </section>
        <br/>
        <br/>
        <section id='fourthElement'>
            <Row className="thirdElement" >
                <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                    <div className='imageContainerx'>
                        <img className='mainImagex' src={largeTomatoe}/>
                    </div>
                </Col>
                <Col xs={0} sm={2} md={2} lg={2} xl={2}>
                </Col>
                <Col xs={12} sm={12} md={7} lg={7} xl={7}>
                    <br/>
                    <br/>
                    <h1 className='titles'>Certifications</h1>
                    <br/>
                    <Row className='justify-content-center'>
                        <Col xs={6} sm={6} md={6} lg={6} xl={4} >
                            <img className='logoHalal' src={HALAL}/>
                            
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={4}>
                                <img className='logoKmd' src={KOSHER} />
                               
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={4}>
                                <img className='logoSqf' src={SQF} />
                        </Col>
                    </Row>
                    <br/>
                    <br/>
                </Col>
            </Row>
        </section>
        <br/>
    </Container>;
}

export default Home;