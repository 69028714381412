import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './products.css';
import ComidaEnlatada from './../../../img/comidasenlatadas.jpg';
import Pasta from './../../../img/tomato-paste-productos.jpg';
import CuboTomate from './../../../img/cubotomate.jpg';
import tarro from './../../../img/tarro.png';
import cajas from './../../../img/cajas.png';


function Products(){
    return <Container className="products-container">
        <section id='zeroElement'>
            <br/>
            <Row className="row">
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                    <br/>
                    <div className='imageContainer'>
                        <img className='image' src={ComidaEnlatada} />
                    </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                    <br/>
                    <br/>
                    <br/>
                    <h1 className='titles'>PRODUCTS</h1>
                    <p className='texts'>
                    Our farmers take care of the tomates from the beginning while they grow in the field. Once harvested, they are manufactured and processed in our facilities to meet our clients standards and necessities. Thanks to the specific customization of each process, we can guarantee a high quality and consistency in our products that can satisfy our clients. In every step of the journey, our team seeks the best flavor, color, and texture in our tomatoes, which turns them into ideal raw material for a great variety of products. 
                    </p>
                </Col>
            </Row>  
        </section> 
        <br/>
        <section id='firstElement'>    
            <Row className="row">
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className='justify-content-center'>
                    <br/>
                    <h1 className='titles'>PACKING</h1>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6} className='justify-content-center' style={{paddingBottom:"10px !important"}}>
                    <br/>
                    <div className='imageContainerEmpaques'>
                        <img className='image' src={tarro}/>
                    </div>
                    <br/>
                    <p> 
                        <h5 >Transport drums:</h5>
                        <div>- Each drum holds an average of 55 gallons or 208 liters. </div>
                        <div>- 238kg +/- per drum. </div>
                        <div>- The drums are 20’ft tall with 80 drums. </div>
                    </p>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6} className='justify-content-center' style={{paddingBottom:"10px !important"}}>
                    <br/>
                    <div className='imageContainerEmpaques'>
                        <img className='image' src={cajas}/>
                    </div>
                    <br/>
                    <p> 
                        <h5>TOTE BIN:</h5>
                        <div>- 300 gallons, wooden tote bin.</div>
                        <div>- 1,279 kg +/- per bin. </div>
                        <div>- The container is 20’ft tall with 15 tote bins </div>
                    </p>
                </Col>
            </Row>
        </section>
        <section id='secondElement'>
            <Row className="row">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h1 className='titles'>TOMATO PASTE</h1>
                    <p className='texts'>
                    The tomato paste is processed from the liquids obtained from the ripe, additive free and freshly produced tomatoes. The raw material adheres to the federal food, drug and cosmetics regulations. The tomato paste is produced according to the client needs. The paste does not contain allergens nor any material previously genetically modified.
                    </p>
                </Col>
            </Row>
            <Row className="row">
                <Col xs={12} sm={12} md={4} lg={4} xl={4} className='justify-content-center'>
                    <p> 
                        <h5>Concentrado</h5>
                        <div>25% NTSS .090 Finish</div>
                        <div>26% NTSS .090, .125, .156, .250 Finish</div>
                        <div>28% NTSS .090, .156, .250 Finish</div>
                        <div>31% NTSS .090 Finish</div>
                        <br/>
                    </p>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4} className='justify-content-center'>
                    <p> 
                        <h5>Hot Break</h5>
                        <div>25% NTSS .060 Finish</div>
                        <div>26% NTSS .060 Finish</div>
                        <div>28% NTSS .033, .045, .060 Finish</div>
                        <div>31% NTSS .027, .033, .045, .060 Finish</div>
                        <div>30-32 BRIX .033, .060 Finish</div>
                    </p>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4} className='justify-content-center'>
                    <p> 
                        <h5>Cold Break</h5>
                        <div>30-32 BRIX .024, .027, .033, .045, .060 Finish</div>
                        <div>31% NTSS .024, .027, .033, .045, .060 Finish</div>
                        <div>36-38 BRIX .024, .027, .033, .045, .060 Finish</div>
                        <div>37% NTSS .024, .027, .033, .045, .060 Fin</div>
                        <br/>
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className='imageContainer'>
                        <img className='image' src={Pasta} />
                    </div>
                </Col>
            </Row>
        </section>
        <section id='thirdElement'>
            <Row className="row">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <br/>
                    <h1 className='titles'>TOMATOES SLICED IN LIQUID CUBES</h1>
                    <p className='texts'>
                    Once the tomatoes are sliced in cubes, they are then processed from the sliced and peeled ripe tomatoes following the food regulations. The raw material adheres to the federal food, drug and cosmetics regulations. The paste does not contain allergens nor any material previously genetically modified.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={0} sm={0} md={0} lg={4} xl={4} className='justify-content-center'>
                </Col>
                <Col xs={12} sm={12} md={12} lg={4} xl={4} className='justify-content-center'>
                    <p> 
                        <h5>Cold Break</h5>
                        <div>3/8", 1/2", 3/4", 1" - with CaCl</div>
                        <div>3/8", 1/2", 3/4", 1" - No CaCl</div>
                    </p>
                </Col>
                <Col xs={0} sm={0} md={0} lg={4} xl={4} className='justify-content-center'>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className='justify-content-center'>
                    <div className='imageContainer'>
                        <img className='image' src={CuboTomate} />
                    </div>
                </Col>
            </Row>
        </section>
        <br/>
    </Container>;
}

export default Products;