import React from "react";
import logo from './bs_foods_logo.png';
import { Link } from "react-router-dom";

export default function NavbarEn(){
    return <nav className='nav'>
        <Link to='/home' className='site-logo'>
            <img className='logo' src={logo}/>
        </Link>
        <ul>
            <Link className='link' to='/company'>
                Company
            </Link>
            <Link className='link' to='/products'>
                 Products
            </Link>
            <Link className='link' to='/logistics'>
                 Logistics
            </Link>
            <Link className='link' to='/contact'>
                 Contact
            </Link>
        </ul>
    </nav>
}