import React, { useRef, useState } from 'react';
import { Input, Grid, TextField, Container, Button} from '@mui/material';
import emailjs from '@emailjs/browser';
import './contact.css';

function ContactUs  (){
  const form = useRef();
  const [data, setData] = useState({
    "nombre":"",
    "correo":"",
    "telefono":"",
    "mensaje":"",
    "correo_envio":"director@bsfoods.com.mx"
  });

  const sendEmail = (e) => {
    e.preventDefault();
    if(data.nombre==""){
      alert('The name is required.');
      return;
    }
    if(data.correo==""){
      alert('Mail is required.');
      return;
    }
    if(data.telefono==""){
      alert('The phone is required.');
      return;
    }
    if(data.mensaje==""){
      alert('The message is required.');
      return;
    }
    emailjs.send('service_r8m2ci2','template_l1q61ag', data, 's6QSGfJIixSlhsWh1').then((result) => {
          console.log('bsfoodfs', result);
          alert('Email sent.')
          setData({
            "nombre":"",
            "correo":"",
            "telefono":"",
            "mensaje":"",
            "correo_envio":"director@bsfoods.com.mx"
          });
      }, (error) => {
          console.log('entro error');
          console.log(error);
          alert('Email was not sent, please try again later.')
      });
  };

  const onChangeMail = (e) =>{
    setData({...data, correo: e.target.value});
  }


  const onChangePhone = (e) =>{
    setData({...data, telefono: e.target.value});
  }

  const onChangeMessage = (e) =>{
    setData({...data, mensaje: e.target.value});
  }

  const onChangeNombre = (e) =>{
    setData({...data, nombre: e.target.value});
  }
 return (
  
    <form onSubmit={sendEmail}>
      
    <Container className='contact-container'>
      <br/>
      <br/>
      <br/>
          <Grid container direction="row" justifyContent="center" spacing={3}>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <h1>Contact</h1>
          </Grid>
          <Grid item xs={12} md={12} lg={6} xl={6}>
            <TextField value={data.nombre} fullWidth={true} label={'Name'} style={{borderColor:"green !important"}} onChange={onChangeNombre} />
          </Grid>
          <Grid item xs={12} md={12} lg={6} xl={6}>
              <TextField value={data.correo} fullWidth={true} label={'Email'} onChange={onChangeMail} />
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={12}>
              <TextField value={data.telefono} fullWidth={true} label={'Phone number'} onChange={onChangePhone}/>
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={12}>
              <TextField value={data.mensaje} fullWidth={true} label={'Message'} multiline rows={10} onChange={onChangeMessage}/>
              
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Button variant="contained" color={'error'} type='submit'>Send</Button>
          </Grid>
        </Grid>
          <br/>
          <br/>
          <br/>
        </Container>
    </form>
    
  );
};

export default ContactUs;