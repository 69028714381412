import React from "react";
import {Route,Routes, Navigate} from  "react-router-dom"
import HomeES from "./pages/home/es/index";
import HomeEN from "./pages/home/en/index";
import ProductsES from "./pages/products/es/products";
import ProductsEN from "./pages/products/en/products";
import LogisticES from "./pages/logistic/es/index";
import LogisticEN from "./pages/logistic/en/index";
import  ContactEN from "./pages/contact/en/index";
import  ContactES from "./pages/contact/es/index";
//import ContactES from "./pages/contact/es/newContact";
//import ContactEN from "./pages/contact/en/newContact";


function Routers(){
    return <>
        <Routes >
            <Route  path="/" element={
                <div style={{backgroundColor:"rgba(253, 83, 83, 0.75) !important"}}>
                    <HomeES/>
                </div>
            } />
            <Route path="/home" element={<HomeEN/>} />
            <Route path="/productos" element={<ProductsES/>} />
            <Route path="/empresa" element={<HomeES/>} />
            <Route path="/logistica" element={<LogisticES/>} />
            <Route path="/contacto" element={<ContactES/>} />
            <Route path="/products" element={<ProductsEN/>} />
            <Route path="/company" element={<HomeEN/>} />
            <Route path="/logistics" element={<LogisticEN/>} />
            <Route path="/contact" element={<ContactEN/>} />
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
    </>
}

export default Routers;