import React from "react";
import './App.css';
import Navbar from './Navbar';
import NavbarEN from './NavbarEn';
import CustomFooter from './footer';
import {useLocation} from 'react-router-dom';
import AppContained from "./AppContained";


function NavbarLanguage  (){
  const location = useLocation();
  var urlpath = location.pathname.toString();
  console.log('pathname', urlpath);
  switch(urlpath){
    case '/company':
      return <NavbarEN/>
    case '/home':
      return <NavbarEN/>
    case '/logistics':
      return <NavbarEN/>
    case '/products':
      return <NavbarEN/>
    case '/contact':
      return <NavbarEN/>
  }
    return <Navbar/>
}

function App() {
  return (
    <>
    
    <script type="text/javascript"
  src="https://cdn.jsdelivr.net/npm/@emailjs/browser@3/dist/email.min.js"></script>

    <script type="text/javascript">
      emailjs.init('qt4xISR1oWa5Oxrcj')
    </script>
    <NavbarLanguage/>
      <body>
        <div class="bg"></div>
        <div class="bg bg2"></div>
        <div class="bg bg3"></div>
        <AppContained/>
      </body>
      <CustomFooter/>
    </>
  
  )
}

export default App;
