import React, {useState, useEffect} from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row';
import {Col} from 'react-bootstrap'
import logo from './bs_foods_logo.png';
import {AiFillPhone, AiFillMail} from 'react-icons/ai';
//import {tractor} from './img/tractor.jpg';
import { 
    Select, 
    Grid, 
    InputLabel, 
    FormControl, 
    MenuItem, 
  } from '@material-ui/core';

const title ='Contacto';

function Combobox(){
    const [selectedDropdown, setSelectedDropdown] = useState({id:1, label: "Español"},);
    const location = useLocation();
    const navigate = useNavigate();
    const list = [
        {id:1, label: "Español"},
        {id:2, label: "English"}
      ];

      useEffect(() => {
        let mounted = true;
        async function fetchData() {
          if (mounted) {
            var urlpath = location.pathname.toString();
            switch(urlpath){
                case '/company':
                    //navigate("/");
                    setSelectedDropdown({id:2, label: "English"});
                    break;
                case '/home':
                    setSelectedDropdown({id:2, label: "English"});
                    break;
                case '/logistics':
                    setSelectedDropdown({id:2, label: "English"});
                    break;
                case '/products':
                    setSelectedDropdown({id:2, label: "English"});
                    break;
                case '/contact':
                    setSelectedDropdown({id:2, label: "English"});
                    break;
                case '/':
                    setSelectedDropdown({id:1, label: "Español"});
                    break;
                case '/logistica':
                    setSelectedDropdown({id:1, label: "Español"});
                    break;
                case '/productos':
                    setSelectedDropdown({id:1, label: "Español"});
                    break;
                case '/contacto':
                    setSelectedDropdown({id:1, label: "Español"});
                    break;
                case '/empresa':
                    setSelectedDropdown({id:1, label: "Español"});
                    break;
            }
          }
        }
        fetchData();
        return () => { mounted = false; };
      }, []);

    const onChangeDropdown = (event) => {
        console.log('change drowpdown', event.target.value);
        var urlpath = location.pathname.toString();
        if(event.target.value.id == 1){
            switch(urlpath){
                case '/company':
                    navigate("/");
                    break;
                case '/home':
                    navigate("/empresa");
                    break;
                case '/logistics':
                    navigate("/logistica");
                    break;
                case '/products':
                    navigate("/productos");
                    break;
                case '/contact':
                    navigate("/contacto");
                    break;
            }
        }else{
            switch(urlpath){
                case '/':
                    navigate("/company");
                    break;
                case '/empresa':
                    navigate("/company");
                    break;
                case '/logistica':
                    navigate("/logistics");
                    break;
                case '/productos':
                    navigate("/products");
                    break;
                case '/contacto':
                    navigate("/contact");
                    break;
            }
        }
        setSelectedDropdown(event.target.value);
    };

    return <Grid item>
    <FormControl variant="filled" style={{color:'white !important'}}>
      <Select
          labelId="id_dp"
          value={selectedDropdown}
          onChange={onChangeDropdown}
          label={'workshop'}
          style={{color: 'white', fontSize:"20px", textDecorationColor: 'green'}}
          renderValue={(value) => `${value == undefined? "": value.label}`}
      >
        {list.map((value)=>(<MenuItem key={value.id} value={value}>{value.label}</MenuItem>))}
      </Select>
    </FormControl>
  </Grid>
}

function CustomFooter(){
     //return <div class="bg-image img2">
     return <div class="footer">
         <Container >
                <br/>
                <Row  >
                    <Col xl={6} sm={6} xs={6}>
                        <div  className='container-logos'>
                            <img className='logo-footer' src={logo}/>
                        </div>
                        
                    </Col>
                    <Col xl={4} sm={4} xs={6}>
                        <Stack>
                            {<div className='title-footer'>BSfoods</div>}
                            <div className='direccion-contact'>Culiacán, Sinaloa (México)</div>
                            <div className='container-data-contact'>
                                <AiFillPhone className='logo-contact'/>
                                <div className='row-contact-content'>+52 6677-13-22-30</div>
                            </div>
                            {/*<div className='container-data-contact'>
                                <AiFillMail className='logo-contact'/>
                                <div className='row-contact-content'>director@bsfoods.com.mx</div>
</div>*/}
                        </Stack>
                    </Col>
                    <Col xl={2} sm={2} xs={6}>
                        <Combobox/>
                    </Col>
                </Row>
                <br/>
            </Container>
     </div> 
}

export default CustomFooter