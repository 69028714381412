import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./styles.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter} from  "react-router-dom"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <React.StrictMode>
    <script type="text/javascript"
  src="https://cdn.jsdelivr.net/npm/@emailjs/browser@3/dist/email.min.js"></script>

  <script type="text/javascript">
    emailjs.init('qt4xISR1oWa5Oxrcj')
  </script>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

