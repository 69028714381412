import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import enbarcacion from './../../../img/barco_enbarcacion.jpg';
import tractocamion from './../../../img/tracto_camion.jpeg';
import "./logistic.css";

function Logistic(){
    return <Container className="logistic-container"> 
        <section id='firstElement'>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <br/>
                    <h1 className='titles'>Logística</h1>
                    <p className='texts'>
                    Nuestro servicio de comercialización y venta esta reforzado por nuestro compromiso incondicional en la mejora continua en nuestro servicio de logística, mismo que contamos y estamos respaldados por nuestros socios estratégicos en servicios de transporte terrestres y marítimo nacional e internacional, así como servicios aduanales competitivos con nuestros socios del ramo.

Es así que garantizamos un servicio integral y de altos estándares en el tema integral de logística cumpliendo desde luego con las normas aplicables en cada tramo de la cadena de suministro.
                    </p>
                </Col>
            </Row>
            <Row>
            <Col xs={0} sm={0} md={0} lg={1} xl={1} className='justify-content-center' style={{paddingBottom:"10px !important"}} />
                <Col xs={12} sm={12} md={12} lg={5} xl={5} className='justify-content-center' style={{paddingBottom:"10px !important"}}>
                    <br/>
                    <div className='imageContainer'>
                        <img className='image' src={enbarcacion}/>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={5} xl={5} className='justify-content-center' style={{paddingBottom:"10px !important"}}>
                    <br/>
                    <div  className='imageContainer' >
                        <img className='image' src={tractocamion} />
                    </div>
                </Col>
                <Col xs={0} sm={0} md={0} lg={1} xl={1} className='justify-content-center' style={{paddingBottom:"10px !important"}} />
            </Row>
        </section>
        <br/>
        <br/>
        <br/>
    </Container>;
}

export default Logistic;