import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './products.css';
import ComidaEnlatada from './../../../img/comidasenlatadas.jpg';
import Pasta from './../../../img/tomato-paste-productos.jpg';
import CuboTomate from './../../../img/cubotomate.jpg';
import tarro from './../../../img/tarro.png';
import cajas from './../../../img/cajas.png';


function Products(){
    return <Container className="products-container">
        <section id='zeroElement'>
            <br/>
            <Row className="row">
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                    <br/>
                    <div className='imageContainer'>
                        <img className='image' src={ComidaEnlatada} />
                    </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                    <br/>
                    <br/>
                    <br/>
                    <h1 className='titles'>PRODUCTOS</h1>
                    <p className='texts'>
                        Nuestros productores cuidan nuestros tomates mientras estos crecen en el campo. Una vez que son cosechados, se llevan para ser concentrados y refinados en nuestras instalaciones más cercanas con tal de cumplir los las necesidades de nuestros clientes. Gracias a la personalización de cada uno de los procesos, garantizamos el nivel deseado de calidad y consistencia de tomates cortados de cubos y puré de tomate que nuestros clientes solicitan. En todo momento, nuestro equipo de campo, producción y control de calidad logran el mejor sabor, color y textura de nuestros tomates, los cuales los convierte en ingredientes ideales para una gran variedad de productos.
                    </p>
                </Col>
            </Row>  
        </section> 
        <br/>
        <section id='firstElement'>    
            <Row className="row">
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className='justify-content-center'>
                    <br/>
                    <h1 className='titles'>EMPAQUES</h1>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6} className='justify-content-center' style={{paddingBottom:"10px !important"}}>
                    <br/>
                    <div className='imageContainerEmpaques'>
                        <img className='image' src={tarro}/>
                    </div>
                    <br/>
                    <p> 
                        <h5 >TAMBOR 55 gal:</h5>
                        <div>- Bolsa aséptica 55 gal, en tambor metálico. </div>
                        <div>- Peso Neto por tambor 238 kg +/- 2. </div>
                        <div>- Contenedor 20’ft con 80 tambores (19,2 MT aprox.). </div>
                    </p>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6} className='justify-content-center' style={{paddingBottom:"10px !important"}}>
                    <br/>
                    <div className='imageContainerEmpaques'>
                        <img className='image' src={cajas}/>
                    </div>
                    <br/>
                    <p> 
                        <h5>TOTE BIN:</h5>
                        <div>- Bolsa aséptica 300 gal, en tote bin de madera. </div>
                        <div>- Peso Neto por tote bin 1.270 kg +/- 2. </div>
                        <div>- Contenedor 20’ft con 15 tote bin (19,2 MT aprox). </div>
                    </p>
                </Col>
            </Row>
        </section>
        <section id='secondElement'>
            <Row className="row">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h1 className='titles'>Pasta de tomate</h1>
                    <p className='texts'>
                        La pasta de tomate se procesa a partir del líquido obtenido de tomates rojos enteros, maduros, sin ningún tipo de aditivo y producidos siguiendo las Buenas Prácticas de Manufactura vigentes. El material se ajusta en cada aspecto de acuerdo a la Ley Federal de Alimentos, Medicamentos y Cosméticos y a las reglamentaciones promulgadas en virtud de la misma. El producto se fabrica según las especificaciones del cliente, que pueden incluir valores que no se enumeran. El producto no contiene alérgenos ni material procedente de organismos modificados genéticamente.
                    </p>
                </Col>
            </Row>
            <Row className="row">
                <Col xs={12} sm={12} md={4} lg={4} xl={4} className='justify-content-center'>
                    <p> 
                        <h5>Concentrado</h5>
                        <div>25% NTSS .090 Finish</div>
                        <div>26% NTSS .090, .125, .156, .250 Finish</div>
                        <div>28% NTSS .090, .156, .250 Finish</div>
                        <div>31% NTSS .090 Finish</div>
                        <br/>
                    </p>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4} className='justify-content-center'>
                    <p> 
                        <h5>Hot Break</h5>
                        <div>25% NTSS .060 Finish</div>
                        <div>26% NTSS .060 Finish</div>
                        <div>28% NTSS .033, .045, .060 Finish</div>
                        <div>31% NTSS .027, .033, .045, .060 Finish</div>
                        <div>30-32 BRIX .033, .060 Finish</div>
                    </p>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4} className='justify-content-center'>
                    <p> 
                        <h5>Cold Break</h5>
                        <div>30-32 BRIX .024, .027, .033, .045, .060 Finish</div>
                        <div>31% NTSS .024, .027, .033, .045, .060 Finish</div>
                        <div>36-38 BRIX .024, .027, .033, .045, .060 Finish</div>
                        <div>37% NTSS .024, .027, .033, .045, .060 Fin</div>
                        <br/>
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className='imageContainer'>
                        <img className='image' src={Pasta} />
                    </div>
                </Col>
            </Row>
        </section>
        <section id='thirdElement'>
            <Row className="row">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <br/>
                    <h1 className='titles'>Tomates cortados en cubos en jugo</h1>
                    <p className='texts'>
                        Los tomates cortados en cubos son procesados a partir de tomates rojos enteros y maduros que sean pelados y cortados en cubos siguiendo las Buenas Prácticas de Manufactura vigentes. El material se ajustará en todos los aspectos a las disposiciones de la Ley Federal de Alimentos, Medicamentos y Cosméticos y las reglamentaciones promulgadas en virtud de la misma. El producto no contiene alérgenos ni material procedente de organismos modificados genéticamente.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={0} sm={0} md={0} lg={4} xl={4} className='justify-content-center'>
                </Col>
                <Col xs={12} sm={12} md={12} lg={4} xl={4} className='justify-content-center'>
                    <p> 
                        <h5>Cold Break</h5>
                        <div>3/8", 1/2", 3/4", 1" - with CaCl</div>
                        <div>3/8", 1/2", 3/4", 1" - No CaCl</div>
                    </p>
                </Col>
                <Col xs={0} sm={0} md={0} lg={4} xl={4} className='justify-content-center'>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className='justify-content-center'>
                    <div className='imageContainer'>
                        <img className='image' src={CuboTomate} />
                    </div>
                </Col>
            </Row>
        </section>
        <br/>
    </Container>;
}

export default Products;