import React from "react";
import logo from './bs_foods_logo.png';
import { Link } from "react-router-dom";

export default function Navbar(){
    return <nav className='nav'>
        <Link to='/' className='site-logo'>
            <img className='logo' src={logo}/>
        </Link>
        <ul>
            <Link className='link' to='/'>
                Empresa
            </Link>
            <Link className='link' to='/productos'>
                 Productos
            </Link>
            <Link className='link' to='/logistica'>
                 Logística
            </Link>
            <Link className='link' to='/contacto'>
                 Contacto
            </Link>
        </ul>
    </nav>
}