import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import enbarcacion from './../../../img/barco_enbarcacion.jpg';
import tractocamion from './../../../img/tracto_camion.jpeg';
import "./logistic.css";

function Logistic(){
    return <Container className="logistic-container"> 
        <section id='firstElement'>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <br/>
                    <h1 className='titles'>Logistics</h1>
                    <p className='texts'>
                    Our shipping and sales service its reinforced by our in conditional commitment to always improve in every aspect of the service. We have strategic partners that helps us to reinforce every logistic service that we offer, international and national, sea transport and inland transportation, as well as a competitive customs service. We guarantee a high quality logistics service that follows every supply chain regulation.
                    </p>
                </Col>
            </Row>
            <Row>
            <Col xs={0} sm={0} md={0} lg={1} xl={1} className='justify-content-center' style={{paddingBottom:"10px !important"}} />
                <Col xs={12} sm={12} md={12} lg={5} xl={5} className='justify-content-center' style={{paddingBottom:"10px !important"}}>
                    <br/>
                    <div className='imageContainer'>
                        <img className='image' src={enbarcacion}/>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={5} xl={5} className='justify-content-center' style={{paddingBottom:"10px !important"}}>
                    <br/>
                    <div  className='imageContainer' >
                        <img className='image' src={tractocamion} />
                    </div>
                </Col>
                <Col xs={0} sm={0} md={0} lg={1} xl={1} className='justify-content-center' style={{paddingBottom:"10px !important"}} />
            </Row>
        </section>
        <br/>
        <br/>
        <br/>
    </Container>;
}

export default Logistic;